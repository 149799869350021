import { Sheet } from "twind";
import { Options, setup, STYLE_ELEMENT_ID } from "./shared.ts";

type State = [string, string][];

export default function hydrate(options: Options, state: State) {
  const el = document.getElementById(STYLE_ELEMENT_ID) as HTMLStyleElement;
  const rules = new Set<string>();
  const precedences: number[] = [];
  const mappings = new Map(
    state.map((v) => typeof v === "string" ? [v, v] : v),
  );
  // deno-lint-ignore no-explicit-any
  const sheetState: any[] = [precedences, rules, mappings, true];
  const target = el.sheet!;
  const ruleText = Array.from(target.cssRules).map((r) => r.cssText);
  for (const r of ruleText) {
    const m = r.lastIndexOf("/*");
    const precedence = parseInt(r.slice(m + 2, -2), 36);
    const rule = r.slice(0, m);
    rules.add(rule);
    precedences.push(precedence);
  }
  const sheet: Sheet = {
    target,
    insert: (rule, index) => target.insertRule(rule, index),
    init: (cb) => cb(sheetState.shift()),
  };
  setup(options, sheet);
}

// denoCacheMetadata={"headers":{"accept-ranges":"bytes","via":"http/2 edgeproxy-h","age":"30762648","cross-origin-resource-policy":"same-origin","last-modified":"Tue, 07 Nov 2023 18:33:27 GMT","x-frame-options":"DENY","server":"deno/gcp-us-east4","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","etag":"\"e835cdf2adf3d06eb24db09bac09265c\"","strict-transport-security":"max-age=63072000; includeSubDomains; preload","x-amz-replication-status":"COMPLETED","x-amz-server-side-encryption":"AES256","referrer-policy":"strict-origin-when-cross-origin","cross-origin-embedder-policy":"same-origin","date":"Fri, 17 Nov 2023 13:09:18 GMT","access-control-allow-origin":"*","vary":"Accept-Encoding, Origin","x-amz-version-id":"ZQfmTFMkxigUN3pM.WFapDSFexOyZMfA","x-cache":"Hit from cloudfront","cross-origin-opener-policy":"same-origin","x-content-type-options":"nosniff","x-amz-cf-id":"xOjKxv7Ws23iCTt2y3kGLZxMtys6y7Eg48kWkxb7xvGzNruhOzPdkQ==","content-length":"1021","x-amz-cf-pop":"IAD61-P1","cache-control":"public, max-age=31536000, immutable","server-timing":"fetchSource;dur=4","content-type":"application/typescript; charset=utf-8"},"url":"https://deno.land/x/fresh@1.5.4/plugins/twind/main.ts","time":1730989205}