import { Options } from "$fresh/plugins/twind.ts";
import * as colors from "twind/colors";
import { getSiteConfig } from "./config/sites.ts";

export function createTwindConfig(siteName?: string): Options {
  // If no siteName provided, try to get it from environment or window
  let site = siteName;
  
  if (!site) {
    // Try to get from Deno environment if we're on the server
    if (typeof Deno !== 'undefined') {
      site = Deno.env.get("CURRENT_SITE");
    }
    // Try to get from window if we're in the browser
    else if (typeof window !== 'undefined') {
      site = window.SITE_NAME;
    }
  }

  if (!site) {
    // Fallback configuration if no site is specified
    return {
      selfURL: import.meta.url,
      theme: {
        extend: {
          colors: {
            ...colors,
            primary: "#000000",
            secondary: "#000000",
            accent: "#000000",
            background: "#ffffff",
            textColor: "#000000",
          },
        },
      },
    };
  }

  // Get site config and create Twind options
  const siteConfig = getSiteConfig(site);
  console.log(`Creating Twind config for site: ${site} with theme:`, siteConfig.theme);
  
  return {
    selfURL: import.meta.url,
    theme: {
      extend: {
        colors: {
          ...colors,
          // Light theme colors
          'light-primary': siteConfig.theme.light.primary,
          'light-secondary': siteConfig.theme.light.secondary,
          'light-accent': siteConfig.theme.light.accent,
          'light-background': siteConfig.theme.light.background,
          'light-text': siteConfig.theme.light.text,
          // Dark theme colors
          'dark-primary': siteConfig.theme.dark.primary,
          'dark-secondary': siteConfig.theme.dark.secondary,
          'dark-accent': siteConfig.theme.dark.accent,
          'dark-background': siteConfig.theme.dark.background,
          'dark-text': siteConfig.theme.dark.text,
          // Dynamic theme colors that will switch based on dark mode
          primary: ({ theme }) => ({
            DEFAULT: theme('colors.light-primary'),
            dark: theme('colors.dark-primary'),
          }),
          secondary: ({ theme }) => ({
            DEFAULT: theme('colors.light-secondary'),
            dark: theme('colors.dark-secondary'),
          }),
          accent: ({ theme }) => ({
            DEFAULT: theme('colors.light-accent'),
            dark: theme('colors.dark-accent'),
          }),
          background: ({ theme }) => ({
            DEFAULT: theme('colors.light-background'),
            dark: theme('colors.dark-background'),
          }),
          textColor: ({ theme }) => ({
            DEFAULT: theme('colors.light-text'),
            dark: theme('colors.dark-text'),
          }),
        },
      },
    },
  };
}

// Export default config for static imports
export default createTwindConfig();
