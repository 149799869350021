import { JSX, options as preactOptions, VNode } from "preact";
import { Configuration, setup as twSetup, Sheet, tw } from "twind";

type PreactOptions = typeof preactOptions & { __b?: (vnode: VNode) => void };

export const STYLE_ELEMENT_ID = "__FRSH_TWIND";

export interface Options extends Omit<Configuration, "mode" | "sheet"> {
  /** The import.meta.url of the module defining these options. */
  selfURL: string;
}

declare module "preact" {
  namespace JSX {
    interface DOMAttributes<Target extends EventTarget> {
      class?: string;
      className?: string;
    }
  }
}

export function setup(options: Options, sheet: Sheet) {
  const config: Configuration = {
    ...options,
    mode: "silent",
    sheet,
  };
  twSetup(config);

  // Hook into options._diff which is called whenever a new comparison
  // starts in Preact.
  const originalHook = (preactOptions as PreactOptions).__b;
  (preactOptions as PreactOptions).__b = (
    // deno-lint-ignore no-explicit-any
    vnode: VNode<JSX.DOMAttributes<any>>,
  ) => {
    if (typeof vnode.type === "string" && typeof vnode.props === "object") {
      const { props } = vnode;
      const classes: string[] = [];
      if (props.class) {
        classes.push(tw(props.class));
        props.class = undefined;
      }
      if (props.className) {
        classes.push(tw(props.className));
        props.className = undefined;
      }
      if (classes.length) {
        props.class = classes.join(" ");
      }
    }

    originalHook?.(vnode);
  };
}

// denoCacheMetadata={"headers":{"x-amz-replication-status":"COMPLETED","access-control-allow-origin":"*","x-content-type-options":"nosniff","date":"Thu, 16 Nov 2023 14:20:01 GMT","content-length":"1520","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","content-type":"application/typescript; charset=utf-8","server":"deno/gcp-us-east4","cross-origin-embedder-policy":"same-origin","cross-origin-resource-policy":"same-origin","strict-transport-security":"max-age=63072000; includeSubDomains; preload","x-amz-version-id":"FWyuZp5I8Vup7B49QZUVM8Sx3K6uvREo","last-modified":"Tue, 07 Nov 2023 18:33:27 GMT","vary":"Accept-Encoding, Origin","accept-ranges":"bytes","server-timing":"fetchSource;dur=14","x-amz-cf-id":"DqJOYwKaIO73Elrjq7NNxHUNWbnzq2ACXJ4Enz4RDP3tuLc0d4Yhxg==","x-amz-cf-pop":"IAD61-P1","cross-origin-opener-policy":"same-origin","etag":"\"856f9a7d74a23a20138b7a72afbd896a\"","via":"http/2 edgeproxy-h","cache-control":"public, max-age=31536000, immutable","x-amz-server-side-encryption":"AES256","x-frame-options":"DENY","x-cache":"Hit from cloudfront","referrer-policy":"strict-origin-when-cross-origin","age":"31523353"},"url":"https://deno.land/x/fresh@1.5.4/plugins/twind/shared.ts","time":1731667753}